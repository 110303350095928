import rq from '@/utils/https.js';
const $ajax = rq.requests;

export const getUserHistory = params =>
    $ajax({
        url: '/user/user-all-history',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
export const getUserList = params =>
    $ajax({
        url: '/user/list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 分享员工列表
export const getStaffList = params =>
    $ajax({
        url: '/user/sys-share-list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 分享动作明细列表

export const getShareInfoList = data =>
    $ajax({
        url: '/user/share-records-list',
        method: 'post',
        data,
    });
export const updateShareStatus = data =>
    $ajax({
        url: '/user/update-share-status',
        method: 'post',
        data,
    });
export const getShareUserList = data =>
    $ajax({
        url: '/user/share-customer-records-list',
        method: 'post',
        data,
    });
export const getDesignerList = params =>
    $ajax({
        url: '/user/getDesignerSortList',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 隐藏设计师
export const hideDesigner = params =>
    $ajax({
        url: '/user/v3.1/designer/supplement/hide',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 删除设计师内容补充
export const deleteDesigner = params =>
    $ajax({
        url: '/user/v3.0/designer/supplement/delete',
        method: 'delete',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 设计师内容回显
export const getDesignerInfo = params =>
    $ajax({
        url: '/user/v3.0/designer/supplement/echo',
        method: 'get',
        params,
    });
// 评价列表
export const getDesignerLink = params =>
    $ajax({
        url: '/user/v3.0/designer/comment/list',
        method: 'get',
        params,
    });
//抓取评价
export const updateDesignerLink = params =>
    $ajax({
        url: '/user/v3.0/designer/comment/grab',
        method: 'get',
        params,
    });
export const updateDesignerInfo = data =>
    $ajax({
        url: '/user/v3.0/designer/supplement',
        method: 'post',
        data,
    });
export const updateDesignerSort = data =>
    $ajax({
        url: '/user/update-designer-sort',
        method: 'post',
        data,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
export const updateTag = data =>
    $ajax({
        url: '/user/user-label',
        method: 'post',
        data,
    });
export const getUserInfo = params =>
    $ajax({
        url: '/user/label-info/' + params.id,
        method: 'get',
    });
//  /labels/operationLabel/list  运营标签
export const operationLabel = params =>
    $ajax({
        url: '/labels/operation/list',
        method: 'get',
        params,
    });

// 编辑岗位
export const updateUserJob = data =>
    $ajax({
        url: '/user/user-job',
        method: 'post',
        data,
    });

// 验证码列表
export const getCodeList = params =>
    $ajax({
        url: '/code/list',
        method: 'get',
        params,
    });

// 获取系统角色
export const getRolesList = params =>
    $ajax({
        url: '/dict/list-roles',
        method: 'get',
        params,
    });

// 潜在用户列表
export const getPotentList = params =>
    $ajax({
        url: '/user/v3.0/potential-list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });

// 跟踪信息
export const getTraceList = params =>
    $ajax({
        url: '/user/v2.2/trace-list',
        method: 'get',
        params,
    });

// 预约信息
export const getAppointmentList = params =>
    $ajax({
        url: '/user/v2.2/appointment-list',
        method: 'get',
        params,
    });
// 用户装修信息
export const getRenovation = params =>
    $ajax({
        url: '/user/v2.2/renovation-info',
        method: 'get',
        params,
    });
// 搜索词统计列表
export const getSearchHit = params =>
    $ajax({
        url: '/sys-search/get-search-hits',
        method: 'get',
        params,
    });
// /user/{version}/customer/demand/list
export const getDemandList = params =>
    $ajax({
        url: '/user/v3.0/customer/demand/list',
        method: 'get',
        params,
    });
// /user/{version}/browsing/history
export const getBrowsingHistory = params =>
    $ajax({
        url: '/user/v3.0/browsing/history',
        method: 'get',
        params,
    });

// 获客来源统计列表
export const getCustomerSourceStatisticsList = params =>
    $ajax({
        url: '/customer-source-statistics/v3.01/list',
        method: 'get',
        params,
    });
// 数据统计列表
export const getCustomerDataStatisticsList = params =>
    $ajax({
        url: '/customer-source-statistics/v3.01/data/statistics/list',
        method: 'get',
        params,
    });
// 数据统计总数
export const getCustomerDataStatisticsTotal = params =>
    $ajax({
        url: '/customer-source-statistics/v3.01/data/statistics/total',
        method: 'get',
        params,
    });

// 用户档案统计 详情
export const getProfileInformation = params =>
    $ajax({
        url: '/user/profile-information',
        method: 'get',
        params,
    });
//  用户档案选项列表
export const getDossierList = params =>
    $ajax({
        url: '/dict/dossier-list',
        method: 'get',
        params,
    });
// 用户预约咨询设计师列表 详情
export const getuserAppointmentList = params =>
    $ajax({
        url: '/user/v3.3/userAppointmentList',
        method: 'get',
        params,
    });
// 用户档案信息-内容发布列表
export const getUserWorksList = params =>
    $ajax({
        url: '/user/article/works/list',
        method: 'get',
        params,
    });
// 用户档案信息-点赞收藏 评论列表
export const getUserAction = data =>
    $ajax({
        url: 'user/get-user-action',
        method: 'post',
        data,
    });
// 用户档案信息-关注-粉丝列表
export const getUserFans = data =>
    $ajax({
        url: 'user/get-user-fans',
        method: 'post',
        data,
    });

// 用户档案统计 列表
export const getDossierUserList = data =>
    $ajax({
        url: '/user/dossier-user-list',
        method: 'post',
        data,
    });
// 用户档案统计 数据统计

export const getDossierStatistics = data =>
    $ajax({
        url: '/user/dossier-statistics',
        method: 'post',
        data,
    });

export const getUserBrowsingList = params =>
    $ajax({
        url: '/user-browsing/v3.3/getUserBrowsingList',
        method: 'get',
        params,
    });
// /user/user-sys-appointment-list
export const getUserSysAppointmentList = params =>
    $ajax({
        url: '/user/user-sys-appointment-list',
        method: 'get',
        params,
    });

export default {};
